// import libs and components
import './app.scss';
import domready from 'domready';
import { loadCss } from './utilities/js/default-css-loader';
import 'lazysizes';
import { ResizeHandler } from './utilities/js/resize-handler';
import { EventScroller } from './utilities/js/event-scroller';
import { loadLazyImages } from './utilities/js/lazy-image-loader';
import { bindTrackingClick } from './utilities/js/tracking';
import { MediaMatchHandler } from './utilities/js/media-match-handler';
import {Categories} from "./areas/categories/categories";

// GLOBAL OPTIONS
const OPTIONS = { // eslint-disable-line
    breakpoints: {
        s: 320,
        m: 768,
        l: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1920
    },
    loader: '<b class="loading"><b class="loading__loader"><i>loading...</i></b></b>'
};

const eventScroller = new EventScroller();
const resizeHandler = new ResizeHandler();
window.mediaMatch = new MediaMatchHandler();

/**
 * define function for initialising modules
 */
const initContent = ($content) => {
    window.checkInView = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                if (!entry.target.classList.contains('inview')) {
                    entry.target.classList.add('inview');

                    if (typeof entry.target.dataset.footer === 'undefined') {
                        window.inViewObserver.unobserve(entry.target);
                    }
                }

                if (typeof entry.target.dataset.footer !== 'undefined') {
                    quickAccessSwitch('off');
                }
            } else {
                if (typeof entry.target.dataset.footer !== 'undefined') {
                    quickAccessSwitch('on');
                }
            }
        });
    };

    window.inViewObserver = new IntersectionObserver(window.checkInView, {
        threshold: 0.1
    });

    const $quickAccess = document.querySelector('[data-quick-access="root"]');
    const quickAccessSwitch = (state) => {
        if (!$quickAccess) {
            return;
        }

        if (state === 'off') {
            $quickAccess.classList.add('is--hidden');
        }
        if (state === 'on') {
            $quickAccess.classList.remove('is--hidden');
        }
    };

    bindTrackingClick($content);
    loadLazyImages();

    // init forms
    const $$forms = $content.querySelectorAll('[data-form="root"]');
    if ($$forms.length > 0) {
        import('./components/form/form-handler').then(({ FormHandler }) => {
            for (let i = 0; i < $$forms.length; i++) {
                const $form = $$forms[i];
                $form.API = new FormHandler($form);
            }
        });
    }

    // init image zoom
    const $imageZooms = $content.querySelectorAll('[data-imagezoom="root"]');
    if ($imageZooms.length > 0) {
        import('./components/image-zoom/image-zoom').then(({ ImageZoom }) => {
            for (let i = 0; i < $imageZooms.length; i++) {
                const $imageZoom = $imageZooms[i];
                $imageZoom.API = new ImageZoom($imageZoom, {
                    loader: OPTIONS.loader
                });
            }
        });
    }

    // init modal
    const $modal = $content.querySelectorAll('[data-modal]');
    if ($modal.length > 0) {
        import('./components/modal/modal').then(({ Modal }) => {
            for (let i = 0; i < $modal.length; i++) {
                const $modalContainer = new Modal($modal[i], {
                    loader: OPTIONS.loader,
                    onAjaxLoaded: ($content) => {
                        initContent($content);
                    }
                });

                $modalContainer.initialize();
            }
        });
    }

    // init add to cart
    const $$addToCart = $content.querySelectorAll('[data-add-to-cart="root"]');
    if ($$addToCart.length > 0) {
        import('./layout/cart/partials/cart-add').then(({ CartAdd }) => {
            for (let i = 0; i < $$addToCart.length; i++) {
                const $addToCart = $$addToCart[i];
                $addToCart.API = new CartAdd($addToCart, {
                    loader: OPTIONS.loader,
                    onSubmit: ($content) => {
                        initContent($content);
                    }
                });
            }
        });
    }

    // init add to reorder cart
    const $$addToCartReorder = $content.querySelectorAll('[data-add-to-cart-reorder="root"]');
    if ($$addToCartReorder.length > 0) {
        import('./layout/cart/partials/cart-reorder').then(({ CartAddReorder }) => {
            for (let i = 0; i < $$addToCartReorder.length; i++) {
                const $addToCartReorder = $$addToCartReorder[i];
                $addToCartReorder.API = new CartAddReorder($addToCartReorder, {
                    loader: OPTIONS.loader,
                    onSubmit: ($content) => {
                        initContent($content);
                    }
                });
            }
        });
    }

    // init update cart
    const $$updateToCart = $content.querySelectorAll('[data-update-cart="root"]');
    if ($$updateToCart.length > 0) {
        import('./layout/cart/partials/cart-update').then(({ CartUpdate }) => {
            for (let i = 0; i < $$updateToCart.length; i++) {
                const $updateCart = $$updateToCart[i];
                $updateCart.API = new CartUpdate($updateCart, {
                    loader: OPTIONS.loader,
                    onSubmit: ($content) => {
                        initContent($content);
                    }
                });
            }
        });
    }

    // init update cart
    const $$removeFromCart = $content.querySelectorAll('[data-remove-cart="root"]');
    if ($$removeFromCart.length > 0) {
        import('./layout/cart/partials/cart-remove').then(({ CartRemove }) => {
            for (let i = 0; i < $$removeFromCart.length; i++) {
                const $removeFromCart = $$removeFromCart[i];
                $removeFromCart.API = new CartRemove($removeFromCart, {
                    loader: OPTIONS.loader,
                    onSubmit: ($content) => {
                        initContent($content);
                    }
                });
            }
        });
    }

    // init add address
    const $$addAddress = $content.querySelectorAll('[data-add-address="root"]');
    if ($$addAddress.length > 0) {
        import('./pages/account/add-address').then(({ AddAddress }) => {
            for (let i = 0; i < $$addAddress.length; i++) {
                const $addAddress = $$addAddress[i];
                $addAddress.API = new AddAddress($addAddress, {
                    loader: OPTIONS.loader,
                    onSubmit: ($content) => {
                        initContent($content);
                    }
                });
            }
        });
    }

    // delete address
    const $deleteAddress = $content.querySelector('[data-delete-address="root"]');
    if ($deleteAddress) {
        import('./pages/account/delete-address').then(({ DeleteAddress }) => {
            $deleteAddress.API = new DeleteAddress($deleteAddress);
        });
    }

    // init tooltip
    const $tooltip = $content.querySelector('[data-tooltip="root"]');
    if ($tooltip) {
        import('./components/tooltip/tooltip').then(({ Tooltip }) => {
            new Tooltip($tooltip); // eslint-disable-line
        });
    }

    // init navigation
    const $navigation = $content.querySelector('[data-nav="root"]');
    if ($navigation) {
        import('./layout/navigation/js/navigation').then(({ Navigation }) => {
            $navigation.API = new Navigation($navigation);
        });
    }

    // init account navigation
    const $accountNavigation = $content.querySelector('[data-account-nav="root"]');
    if ($accountNavigation) {
        import('./layout/navigation/js/account-navigation').then(({ AccountNavigation }) => {
            $accountNavigation.API = new AccountNavigation($accountNavigation);
        });
    }

    // init minicart
    const $minicart = $content.querySelectorAll('[data-minicart="root"]');
    if ($minicart.length > 0) {
        import('./layout/cart/minicart').then(({ Minicart }) => {
            for (let i = 0; i < $minicart.length; i++) {
                $minicart[i].API = new Minicart($minicart[i]);
            }
        });
    }

    // init checkout
    const $checkout = $content.querySelector('[data-checkout="root"]');
    if ($checkout) {
        import('./pages/checkout/checkout').then(({ Checkout }) => {
            $checkout.API = new Checkout($checkout);
        });
    }

    // init language switch
    const $languageSwitch = $content.querySelector('[data-language-switch="root"]');
    if ($languageSwitch) {
        import('./layout/navigation/js/language-switch').then(({ LanguageSwitch }) => {
            $languageSwitch.API = new LanguageSwitch($languageSwitch);
        });
    }

    // init contact form
    const $contactForm = $content.querySelector('[data-form-contact="root"]');
    if ($contactForm) {
        import('./pages/contact/contact').then(({ ContactForm }) => {
            $contactForm.API = new ContactForm($contactForm);
        });
    }

    // init faq
    const $faq = $content.querySelector('[data-faq="root"]');
    if ($faq) {
        import('./areas/faq/faq').then(({ Faq }) => {
            $faq.API = new Faq($faq);
        });
    }

    // init installation service module
    const $serviceModule = $content.querySelectorAll('[data-service="root"]');
    if ($serviceModule.length > 0) {
        import('./areas/installation-service-module/installation-service-module').then(({ InstallationServiceModule }) => {
            for (let i = 0; i < $serviceModule.length; i++) {
                const serviceModule = $serviceModule[i];
                serviceModule.API = new InstallationServiceModule(serviceModule);
            }
        });
    }

    // init floating action bar
    const $floatingActionBar = $content.querySelectorAll('[data-fab="root"]');
    if ($floatingActionBar.length > 0) {
        import('./areas/floating-action-bar/floating-action-bar').then(({ FloatingActionBar }) => {
            for (let i = 0; i < $floatingActionBar.length; i++) {
                const floatingActionBar = $floatingActionBar[i];
                floatingActionBar.API = new FloatingActionBar(floatingActionBar);
            }
        });
    }

    // init service teaser
    const $serviceTeaser = $content.querySelectorAll('[data-service-teaser="root"]');
    if ($serviceTeaser.length > 0) {
        import('./areas/service-teaser/service-teaser').then(({ ServiceTeaser }) => {
            for (let i = 0; i < $serviceTeaser.length; i++) {
                const serviceTeaser = $serviceTeaser[i];
                serviceTeaser.API = new ServiceTeaser(serviceTeaser);
            }
        });
    }

    // init slider teaser
    const $sliderTeaser = $content.querySelectorAll('[data-slider-teaser="root"]');
    if ($sliderTeaser.length > 0) {
        import('./areas/slider-teaser/slider-teaser').then(({ SliderTeaser }) => {
            for (let i = 0; i < $sliderTeaser.length; i++) {
                const sliderTeaser = $sliderTeaser[i];
                sliderTeaser.API = new SliderTeaser(sliderTeaser);
            }
        });
    }

    // init step teaser
    const $stepTeaser = $content.querySelectorAll('[data-step-teaser="root"]');
    if ($stepTeaser.length > 0) {
        import('./areas/step-teaser/step-teaser').then(({ StepTeaser }) => {
            for (let i = 0; i < $stepTeaser.length; i++) {
                const stepTeaser = $stepTeaser[i];
                stepTeaser.API = new StepTeaser(stepTeaser);
            }
        });
    }

    // init tab module
    const $tabs = $content.querySelectorAll('[data-tabs-module="root"]');
    if ($tabs.length > 0) {
        import('./areas/tab-module/tab-module').then(({ Tabs }) => {
            for (let i = 0; i < $tabs.length; i++) {
                const tabs = $tabs[i];
                tabs.API = new Tabs(tabs);
            }
        });
    }

    // init video module
    const $video = $content.querySelectorAll('[data-video-module="root"]');
    if ($video.length > 0) {
        import('./areas/video/video').then(({ Video }) => {
            for (let i = 0; i < $video.length; i++) {
                const videoModule = $video[i];
                videoModule.API = new Video(videoModule);
            }
        });
    }

    // init revocation
    const $revocation = $content.querySelectorAll('[data-revocation-order-item="root"]');
    if ($revocation.length > 0) {
        import('./pages/revocation/revocation-order-item').then(({ Revocation }) => {
            new Revocation($revocation); // eslint-disable-line
        });
    }

    // init revocation details item
    const $revocationDetailItem = $content.querySelector('[data-revocation-detail-items="root"]');
    if ($revocationDetailItem) {
        import('./pages/revocation/revocation-details-item').then(({ RevocationItem }) => {
            new RevocationItem(); // eslint-disable-line
        });
    }

    // init account order
    const $accountOrder = $content.querySelector('[data-account-order="root"]');
    if ($accountOrder) {
        import('./pages/account/account-order').then(({ AccountOrder }) => {
            new AccountOrder($accountOrder, { // eslint-disable-line
                onSubmit: ($content) => {
                    initContent($content);
                }
            });
        });
    }

    // init product detail
    const $productDetail = $content.querySelectorAll('[data-product-detail="root"]');
    if ($productDetail.length > 0) {
        import('./pages/product/product-detail/product-detail').then(({ ProductDetail }) => {
            for (let i = 0; i < $productDetail.length; i++) {
                const productDetail = $productDetail[i];
                productDetail.API = new ProductDetail(productDetail);
            }
        });
    }

    // init product filter
    const $productFilter = $content.querySelectorAll('[data-product-filter="root"]');
    if ($productFilter.length > 0) {
        import('./pages/product/product-overview/product-filter').then(({ Filter }) => {
            for (let i = 0; i < $productFilter.length; i++) {
                const productFilter = $productFilter[i];
                productFilter.API = new Filter(productFilter);
            }
        });
    }

    // init product overview
    const $productOverview = $content.querySelectorAll('[data-product-overview="root"]');
    if ($productOverview.length > 0) {
        import('./pages/product/product-overview/product-overview').then(({ ProductOverview }) => {
            for (let i = 0; i < $productOverview.length; i++) {
                const productOverview = $productOverview[i];
                productOverview.API = new ProductOverview(productOverview, {
                    loader: OPTIONS.loader,
                    onSubmit: ($content) => {
                        initContent($content);
                    }
                });
            }
        });
    }

    // init product filter chips
    const $filterChips = $content.querySelectorAll('[data-filter-chips="root"]');
    if ($filterChips.length > 0) {
        import('./pages/product/product-overview/product-filter-chips').then(({ FilterChips }) => {
            for (let i = 0; i < $filterChips.length; i++) {
                const filterChips = $filterChips[i];
                filterChips.API = new FilterChips(filterChips);
            }
        });
    }

    // init product search
    const $$productSearch = $content.querySelectorAll('[data-product-search="root"]');
    if ($$productSearch.length > 0) {
        import('./pages/product/product-overview/product-search').then(({ ProductSearch }) => {
            for (let i = 0; i < $$productSearch.length; i++) {
                const $productSearch = $$productSearch[i];
                $productSearch.API = new ProductSearch($productSearch);
            }
        });
    }

    // init product teaser carousel
    const $productTeaserCarousel = $content.querySelectorAll('[data-product-carousel="root"]');
    if ($productTeaserCarousel.length > 0) {
        import('./areas/product-teaser-carousel/product-teaser-carousel').then(({ ProductCarousel }) => {
            for (let i = 0; i < $productTeaserCarousel.length; i++) {
                const productTeaserCarousel = $productTeaserCarousel[i];
                productTeaserCarousel.API = new ProductCarousel(productTeaserCarousel);
            }
        });
    }

    // init reorder carousel
    const $$reorderCarousel = $content.querySelectorAll('[data-reorder="root"]');
    if ($$reorderCarousel.length > 0) {
        import('./areas/reorder/reorder').then(({ Reorder }) => {
            for (let i = 0; i < $$reorderCarousel.length; i++) {
                const $reorderCarousel = $$reorderCarousel[i];
                $reorderCarousel.API = new Reorder($reorderCarousel);
            }
        });
    }

    // init news section
    const $newsSectionCarousel = $content.querySelectorAll('[data-news-section="root"]');
    if ($newsSectionCarousel.length > 0) {
        import('./areas/news-section/news-section').then(({ NewsSection }) => {
            for (let i = 0; i < $newsSectionCarousel.length; i++) {
                const newsSectionCarousel = $newsSectionCarousel[i];
                newsSectionCarousel.API = new NewsSection(newsSectionCarousel);
            }
        });
    }

    // init categories
    const $categories = $content.querySelectorAll('[data-categories="root"]');
    if ($categories.length > 0) {
        import('./areas/categories/categories').then(({ Categories }) => {
            for (let i = 0; i < $categories.length; i++) {
                const categories = $categories[i];
                categories.API = new Categories(categories);
            }
        });
    }

    // init scroll button
    const $scrollButton = $content.querySelectorAll('[data-scroll-button="root"]');
    if ($scrollButton.length > 0) {
        import('./components/scroll-to-top/scroll-to-top').then(({ ScrollToTop }) => {
            for (let i = 0; i < $scrollButton.length; i++) {
                const scrollButton = $scrollButton[i];
                scrollButton.API = new ScrollToTop(scrollButton);
            }
        });
    }

    // init sales banner
    const $salesBanner = $content.querySelectorAll('[data-sales-banner="root"]');
    if ($salesBanner.length > 0) {
        import('./components/sales-banner/sales-banner').then(({ SalesBanner }) => {
            for (let i = 0; i < $salesBanner.length; i++) {
                const salesBanner = $salesBanner[i];
                salesBanner.API = new SalesBanner(salesBanner);
            }
        });
    }

    // init order search
    const $orderSearch = $content.querySelectorAll('[data-order-search="root"]');
    if ($orderSearch.length > 0) {
        import('./pages/account/order/order-search').then(({ OrderSearch }) => {
            for (let i = 0; i < $orderSearch.length; i++) {
                const orderSearch = $orderSearch[i];
                orderSearch.API = new OrderSearch(orderSearch);
            }
        });
    }

    // init order load more
    const $orderLoad = $content.querySelectorAll('[data-order-overview="root"]');
    if ($orderLoad.length > 0) {
        import('./pages/account/order/order-load-more').then(({ OrderLoadMore }) => {
            for (let i = 0; i < $orderLoad.length; i++) {
                const orderLoad = $orderLoad[i];
                orderLoad.API = new OrderLoadMore(orderLoad, {
                    loader: OPTIONS.loader,
                    onSubmit: ($content) => {
                        initContent($content);
                    }
                });
            }
        });
    }
};

/**
 * starting point
 */

domready(function () {
    window.addEventListener('cssLoaded', () => {
        initContent(document.querySelector('body'));
        eventScroller.init();
        resizeHandler.init();
    });

    window.dataInview = document.querySelectorAll('[data-inview]:not(.inview)');
    setTimeout(() => {
        window.dataInview.forEach((dataInView) => {
            window.inViewObserver.observe(dataInView);
        });
    }, 500);

    loadCss();
});
